import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Auth, User } from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AccountManager } from "./AccountManager";
import { Header } from "./common/Header";
import { PageTitle } from "./typography/PageTitle";
import { StartUsingButton } from "./login/StartUsingButton";

export const LoginOrSignup = ({
  auth,
  user,
  loading,
}: {
  auth: Auth;
  user: User | null | undefined;
  loading: boolean;
}) => {
  const [message, setMessage] = useState("");
  const { callback } = useParams();
  const navigate = useNavigate();

  if (user) {
    return (
      <AccountManager currentUser={user} auth={auth} callbackKey={callback} />
    );
  }
  return (
    <>
      <Header />

      <Container sx={{ maxWidth: "md", my: 5 }}>
        <Box sx={{ textAlign: "center" }}>
          <PageTitle>ログイン・新規登録</PageTitle>
          <Typography variant="body1" component="p">
            本サービスは、Puzzle Square
            JPのアカウントと連携することで、より便利にご利用いただくことができます。
          </Typography>
        </Box>
        <Container maxWidth="sm" sx={{ mx: "auto" }}>
          <Stack spacing={5} sx={{ my: 5 }}>
            <Box>
              <Typography variant="h5">
                Puzzle Square JPのアカウントを持っている場合
              </Typography>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1">
                  下記ボタンよりLogicpuzzleAppアカウントにログインし、サービスのページに戻ることで連携機能をご利用いただくことができます。
                </Typography>
              </Box>

              <Button
                component={Link}
                to={"/login/" + callback}
                variant="contained"
                fullWidth
              >
                <Typography variant="h6" component="h6">
                  ログインページへ
                </Typography>
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>
              <Typography variant="h5">
                Puzzle Square JPのアカウントをお持ちでない場合
              </Typography>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1">
                  以下の手順でアカウントを作成し、その後サービスのページに改めてアクセスすることで連携機能をご利用いただくことができます。
                </Typography>
                <Box sx={{ my: 1 }} />
                <Typography variant="body1">
                  1. 下記ボタンよりLogicpuzzleappアカウントを作成する
                </Typography>
                <Typography variant="body1">
                  2. Puzzle Square JPのページに移動し、アカウントを新規作成する
                </Typography>
              </Box>

              <Button
                component={Link}
                to={"/login/" + callback}
                variant="contained"
                sx={{ mx: "auto" }}
                fullWidth
              >
                <Typography variant="h6" component="h6">
                  LogicPuzzleAppアカウント作成ページへ
                </Typography>
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <StartUsingButton callback={callback} />
          </Stack>
        </Container>
      </Container>
    </>
  );
};
