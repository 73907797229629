import { Auth, getAuth, signOut, User } from "@firebase/auth";
import {
  AppBar,
  Backdrop,
  Button,
  Container,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import "firebase/functions";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { disableCookie } from "../api/logoutApi";
import { AccountInfo } from "./login/AccountInfo";
import { ChangeMailaddress } from "./login/mail/ChangeMailaddress";
import { ChangePassword } from "./login/mail/ChangePassword";
import { MailLogin } from "./login/mail/MailLogin";
import { ProviderLoginButton } from "./login/provider/ProviderLogin";
import { ProviderUnlinkButton } from "./login/provider/ProviderUnlink";
import { StartUsingButton } from "./login/StartUsingButton";
import { ProviderId } from "./login/type";
import { Header } from "./common/Header";
require("firebase/auth");

const providerIdArray: Array<ProviderId> = ["twitter.com", "google.com"];

export type AccountManagerProps = {
  currentUser: User;
  auth: Auth;
  callbackKey?: string;
};

export const AccountManager = ({
  currentUser,
  auth,
  callbackKey,
}: AccountManagerProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { callback } = useParams();
  const [email, setEmail] = useState(currentUser.email);
  const [providerData, setProviderData] = useState(currentUser.providerData);
  useEffect(() => {
    setEmail(currentUser.email);
    setProviderData(currentUser.providerData);
  }, [currentUser]);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setEmail(user.email);
      setProviderData(user.providerData);
    }
  });

  const logout = async () => {
    setIsButtonDisabled(true);
    //cookieの削除
    await disableCookie();
    //firebase authからのログアウト
    const auth = getAuth();
    await signOut(auth);
    setIsButtonDisabled(false);
  };
  const [mailUpdateMessage, setMailUpdateMessage] = useState("");
  const [mailLoginMessage, setMailLoginMessage] = useState("");
  const [unlinkMessage, setUnlinkMessage] = useState("");

  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      />
      <Container sx={{ maxWidth: "md", my: 5 }}>
        <Typography variant="h5" component="h3">
          ログインしました
        </Typography>

        <AccountInfo currentUser={currentUser} />

        <Grid container>
          <Container component="main" maxWidth="xs">
            <Grid item xs={12} sx={{ my: 2 }}>
              <p>サービスの利用を開始するには</p>
              <StartUsingButton callback={callback} />
            </Grid>
          </Container>
        </Grid>
        <Grid container>
          <Container component="main" maxWidth="xs">
            <p>別のアカウントでログインするには</p>
            <Button onClick={logout} variant="contained">
              <Typography variant="h6" component="h6">
                ログアウト
              </Typography>
            </Button>
          </Container>
        </Grid>

        {(!providerData.find((e) => e.providerId === "twitter.com") ||
          !email) && (
          <>
            <Divider sx={{ m: 2 }} />
            <Container component="main" maxWidth="xs">
              <Typography variant="h5">
                Twitter認証以外の認証を登録することを強く推奨いたします。
              </Typography>
              <p>アカウント連携し他の手段でもログインできるようにするには</p>
              {!email && (
                <>
                  <MailLogin
                    auth={auth}
                    setMessage={setMailLoginMessage}
                    callbackKey={callbackKey}
                    hideResetPassword
                  />
                  <Typography variant="body1" color="error">
                    {mailLoginMessage}
                  </Typography>
                </>
              )}
              {!providerData.find((e) => e.providerId === "google.com") &&
                !email && (
                  <ProviderLoginButton providerId="google.com" auth={auth}>
                    googleでログイン
                  </ProviderLoginButton>
                )}
              {!providerData.find((e) => e.providerId === "twitter.com") && (
                <ProviderLoginButton providerId="twitter.com" auth={auth}>
                  twitterでログイン
                </ProviderLoginButton>
              )}
            </Container>
          </>
        )}

        {providerData.find((e) => e.providerId === "twitter.com") && email && (
          <>
            <Divider sx={{ m: 2 }} />
            <Container component="main" maxWidth="xs">
              <p>アカウント連携を解除するには</p>
              {providerData.find((e) => e.providerId === "twitter.com") && (
                <>
                  <ProviderUnlinkButton
                    providerId="twitter.com"
                    auth={auth}
                    setMessage={setUnlinkMessage}
                    setProviderData={setProviderData}
                  >
                    twitter連携解除
                  </ProviderUnlinkButton>
                  <Typography variant="body1">{unlinkMessage}</Typography>
                </>
              )}
              <p>※google連携の解除は現在できません。</p>
            </Container>
          </>
        )}
        {!providerData.find((e) => e.providerId === "google.com") && email && (
          <>
            <Divider sx={{ m: 2 }} />
            <Container component="main" maxWidth="xs">
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ChangeMailaddress
                      auth={auth}
                      setMessage={setMailUpdateMessage}
                      setEmail={setEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword
                      auth={auth}
                      setMessage={setMailUpdateMessage}
                    />
                  </Grid>
                </Grid>
                {mailUpdateMessage}
              </>
            </Container>
          </>
        )}
      </Container>
    </>
  );
};
