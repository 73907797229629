import { Auth, User } from "@firebase/auth";
import {
  AppBar,
  Backdrop,
  Card,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import "firebase/functions";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { checkRedirectResult } from "../api/loginApi";
import { AccountManager } from "./AccountManager";
import { PasswordResetMail } from "./login/mail/PasswordResetMail";
import { MailArea } from "./login/MailArea";
import { ProviderArea } from "./login/ProviderArea";
import { ProviderId } from "./login/type";
import { loginErrorHandler } from "./login/util/loginErrorHandler";
import { NowLoading } from "./NowLoading";
import { PageTitle } from "./typography/PageTitle";
import { Header } from "./common/Header";
require("firebase/auth");

const providerIdArray: Array<ProviderId> = ["twitter.com", "google.com"];

type PasswordResetProps = {
  auth: Auth;
  user: User | null | undefined;
  loading: boolean;
};

export const PasswordReset = ({ auth, user, loading }: PasswordResetProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const { callback } = useParams();

  useEffect(() => {
    setIsButtonDisabled(true);
    checkRedirectResult(auth)
      .catch((e) => {
        loginErrorHandler(e, setMessage);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  }, []);

  if (loading || user === undefined) {
    return <NowLoading />;
  }

  if (user) {
    return (
      <AccountManager currentUser={user} auth={auth} callbackKey={callback} />
    );
  }

  return (
    <>
      <Header />

      <Container sx={{ maxWidth: "md", my: 5 }}>
        <PageTitle>パスワードの再設定</PageTitle>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isButtonDisabled}
        />
        <Grid container sx={{ my: 2 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <PasswordResetMail callback={callback} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
